
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "../../styles/globals.scss";
import "../../styles/xs.scss";
import "../../styles/easyticket.scss";
import "../../styles/BuyScreen.scss";
import "../../styles/fonts.scss";
import "../../styles/invitation_form.scss";
import("../../styles/easyticket" + process.env.LOCALES_SUFFIX + ".scss");
import { RecoilRoot } from "recoil";
import { SessionProvider } from "next-auth/react";
import { useRouter } from "next/router";
import { ToastProvider } from "react-toast-notifications";
import { CustomToastContainer } from "../components/toast";
import { useEffect, useState } from "react";
import Layout from "../layout/layout";
import Hoc from "../hoc/Hoc";
import { clarity } from 'react-microsoft-clarity';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getDataFromLocal } from "../helpers/localStorage";
import * as Sentry from "@sentry/nextjs";
import { ThemeProvider } from "@material-tailwind/react";
const _app = ({ Component, pageProps }) => {
    const router = useRouter();
    const [openSearchEasyTicket, setOpenSearchEasyTicket] = useState(false);
    const stripeConfig = {
        ...(process.env.FEDERLEGNO_STRIPE_ID !== "unused" ? { stripeAccount: process.env.FEDERLEGNO_STRIPE_ID } : {})
    };
    const stripePromise = loadStripe(process.env.DEVELON_STRIPE_PUBLIC_KEY, stripeConfig);
    setInterval(() => {
        let localStorageCustomer = null;
        let loginProcessCompleted = null;
        let navBarCustomersInSession = null;
        try {
            localStorageCustomer = getDataFromLocal("customer");
            loginProcessCompleted = getDataFromLocal("loginProcessCompleted") === "true" ? true : false;
            if (localStorageCustomer) {
                localStorageCustomer = JSON.parse(localStorageCustomer);
            }
            const completedLoginWithDirtyCustomer = (loginProcessCompleted === true && (!localStorageCustomer || !localStorageCustomer.id || !localStorageCustomer.customer_type)) && router.asPath !== "/logout";
            const incompleteLoginWithDirtyCustomer = loginProcessCompleted === false && localStorageCustomer === null && router.asPath !== "/company" && router.asPath !== "/logout";
            if ((completedLoginWithDirtyCustomer || incompleteLoginWithDirtyCustomer)) { // TODO: find a way to restrict this better
                throw new Error("Customer not found");
            }
        }
        catch (error) {
            Sentry.captureException(new Error("Customer conformity check failed:" + JSON.stringify(localStorageCustomer) || ""));
            router.push("/logout");
        }
    }, 1 * 10 * 1000);
    pageProps.pagePath = router.asPath;
    const [currentEasyTicketSelectedTab, setCurrentEasyTicketSelectedTab] = useState(0);
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID && process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID !== "unused") {
            if (!clarity.hasStarted()) {
                clarity.init(process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID);
            }
        }
    }, []);
    const handlerChangeTab = (value) => {
        setCurrentEasyTicketSelectedTab(value);
        setOpenSearchEasyTicket(false);
    };
    const appContent = (<ToastProvider placement="bottom-center" autoDismiss autoDismissTimeout={55000} components={{ ToastContainer: CustomToastContainer }}>
      {router.asPath === "/logout"
            || router.asPath.startsWith("/unauthorized")
            || router.asPath.includes("/tickets_wizard")
            ? (
            /*
              TODO _CR_:
              escludendo il layout le pagine perdono il toast provider e a tutte le logiche di gestione dell'HOC
            */
            <Component {...pageProps}/>) : (<Hoc>
            <Layout {...pageProps} currentEasyTicketSelectedTab={currentEasyTicketSelectedTab} setCurrentEasyTicketSelectedTab={handlerChangeTab}>
              <Component {...pageProps} currentEasyTicketSelectedTab={currentEasyTicketSelectedTab} setCurrentEasyTicketSelectedTab={handlerChangeTab} openSearch={openSearchEasyTicket} setOpenSearch={setOpenSearchEasyTicket}/>
            </Layout>
          </Hoc>)}
    </ToastProvider>);
    return (<RecoilRoot>
      <SessionProvider options={{
            clientMaxAge: 60,
            keepAlive: 5 * 60,
        }} session={pageProps.session}>
        <Elements stripe={stripePromise} options={{ locale: "it-IT" }}>
          <ThemeProvider>
            {appContent}
          </ThemeProvider>
        </Elements>
      </SessionProvider>
    </RecoilRoot>);
};
const __Next_Translate__Page__194fab4b2d1__ = _app;

    export default __appWithI18n(__Next_Translate__Page__194fab4b2d1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  